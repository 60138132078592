import { createGlobalStyle, css } from "styled-components";
import {
  Black,
  hover,
  text,
  title,
  Transition,
  White,
} from "./globalStyleVars";

function createCSS() {
  let styles = "";

  for (let i = 2; i < 20; i += 1) {
    styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * 0.12}s;
        }
     `;
  }

  for (let a = 2; a < 100; a += 1) {
    styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * 0.03}s;
        }
     `;
  }

  return css`
    ${styles}
  `;
}

export default createGlobalStyle`

    ${createCSS()}
    #root {
        min-height: 100vh;
        overflow-x: hidden;
    }


  
    * {
        scrollbar-width: thin; /* Thin scrollbar */
        scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
    }
    * {
        scrollbar-width: thin; /* Thin scrollbar */
        scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
    }

    html {
        /* For Webkit browsers (Chrome, Safari) */
        &::-webkit-scrollbar {
            width: 4px; /* Adjust width as needed */
            height: 4px; /* Adjust height as needed */
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1; /* Background of the scrollbar track */
            display: none;
            
        }

        &::-webkit-scrollbar-thumb {
            background: #888; /* Color of the scrollbar thumb */
            border-radius: 15px; /* Rounded corners */
            display: none;
            
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555; /* Color of the scrollbar thumb on hover */
            display: none;
            
        }

        -ms-overflow-style: -ms-autohiding-scrollbar;
        overflow-style: auto;
        scrollbar-width: thin; /* Thin scrollbar */
        scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
    }

    body::-webkit-scrollbar {
        display: none;
    }

    body {
        -ms-overflow-style: none;
    }


    body {
        color: ${text};
        padding: 0;
        overflow-x: hidden;
        min-height: 100vh;
        font-size: 16px;
        line-height: 20px;
        background-color: white;
        /* font-family: 'Aeonik', Euclid Square, Arial, Helvetica, freesans, sans-serif; */
        /* scroll-behavior: smooth; */
        text-rendering: optimizeSpeed;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @media (min-width: 1024px) {
            -webkit-text-size-adjust: 100%;
            -moz-text-size-adjust: 100%;
            text-size-adjust: 100%;
            overscroll-behavior: none;
        }
    }

    a {
        transition: color .3s ease;
        text-decoration: none;

        &:hover {
            color: ${hover} !important;
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }

        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
            color: ${text};
        }
    }

    ::selection {
        background: ${hover};
        color: #FFF;
    }

    p, a, h1, h2, h4, h3, h5, h6 {
        font-weight: 400;
        margin: 0;
    }

    h1, h2 {
        font-family: 'Butler';
        font-weight: 500;
    }

    ul {
        margin: 0;
        padding: 0
    }

    li {
        list-style: none;
    }

    img {
        max-width: 100%;
        object-fit: contain;
    }


    .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
        outline: none;
        box-shadow: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 1px solid rgba(0, 0, 0, 0);
        -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
        transition: background-color 5000s ease-in-out 0s;
    }


    table {
        width: 100%;
    }

    form div {
        position: relative;
    }

    .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;

        &:focus {
            box-shadow: none;
        }
    }

    .p-0 {
        padding: 0 !important;
    }

    .pl-0 {
        padding-left: 0;
    }

    .pr-0 {
        padding-right: 0;
    }

    .pt-200 {
        padding-top: 200px;
        @media (max-width: 767px) {
            padding-top: 100px;
        }
    }

    .pb-200 {
        padding-bottom: 200px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pt-160 {
        padding-top: 160px;
        @media (max-width: 767px) {
            padding-top: 100px;
        }
    }

    .pb-160 {
        padding-bottom: 160px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pt-150 {
        padding-top: 150px;
        @media (max-width: 767px) {
            padding-top: 100px;
        }
    }

    .pb-150 {
        padding-bottom: 150px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pt-120 {
        padding-top: 120px;
        @media (max-width: 767px) {
            padding-top: 100px;
        }
    }

    .pb-120 {
        padding-bottom: 120px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pb-130 {
        padding-bottom: 130px;
        @media (max-width: 767px) {
            padding-bottom: 100px;
        }
    }

    .pt-100 {
        padding-top: 100px;
        @media (max-width: 767px) {
            padding-top: 60px;
        }
    }

    .pb-100 {
        padding-bottom: 100px;
        @media (max-width: 767px) {
            padding-bottom: 60px;
        }
    }

    .pt-80 {
        padding-top: 80px;
        @media (max-width: 767px) {
            padding-top: 40px;
        }
    }

    .pb-80 {
        padding-bottom: 80px;
        @media (max-width: 767px) {
            padding-bottom: 40px;
        }
    }

    .mt-20 {
        margin-top: 20px;
    }

    .mt-40 {
        margin-top: 40px;
    }

    .mt-60 {
        margin-top: 60px;
    }


    @media (min-width: 1500px) {
        .container {
            min-width: 85%;
            max-width: 85%;
            margin: auto;
            .row{
                width: 100%;
            }
        }
    }

    @media (max-width: 1199px) and (min-width: 768px) {
        .container, .container-lg, .container-md, .container-sm {
            max-width: 90%;
            margin: auto;
        }
    }


    @media (max-width: 767px) {
        .container, .container-sm {
            max-width: 100%;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }


    //react select
    .css-yk16xz-control, .css-1pahdxg-control {
        height: 50px;
        border-radius: 0 !important;
        padding-left: 5px;
        font-size: 16px;
        outline: none !important;
        border-color: #D9D9D9 !important;
        box-shadow: none !important;

        .css-1wa3eu0-placeholder {
            font-weight: 300;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.5);
            outline: none;
        }

        .css-1okebmr-indicatorSeparator {
            display: none;
        }

        .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
            margin-right: 10px;
        }
    }

    .css-2613qy-menu {
        border-radius: 0 !important;
        margin-top: 0 !important;
    }


    .info-window {
        max-width: 200px;
    }

    .gm-style-iw {
        border-radius: 0 !important;
    }

    .swiper-pagination-bullet {
        outline: none;
    }

    .css-nmuc1a-menu {
        z-index: 5 !important;
    }


    .map-info__img {
        img {
            height: 100px;
            margin-bottom: 12px;
            object-fit: cover;
        }
    }

    .map-info__content {
        h4 {
            font-size: 20px;
        }

        p {
            margin-bottom: 5px;
        }
    }

    .overlay {
        position: fixed;
        height: 100vh;
        width: 100%;
        //background-color: rgba(0,0,0,0.5);
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 9;
        display: none;

        &.show {
            display: block;
        }
    }

    .form-control.has-error {
        border-color: #dc004e !important;
    }

    .has-error .find-retainer-filter__control {
        border-color: #dc004e !important;
    }

    //preloader
    .content-loader {
        position: absolute;
        height: 70%;
        width: 70%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .loading-before-submit {
        position: fixed;
        height: 100vh;
        width: 100%;
        bottom: 0;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 40px;
        }
    }


    .swiper-slide {
        height: auto;
    }

    .slick-slide {
        div {
            outline: none !important
        }
    }

    button, button:active, button:focus, button:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }


    .hover {
        position: relative;
        overflow: hidden;

        span {
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: ${hover};
            transition: all .5s ease;
            border-radius: 19px;
        }

        &:hover {
            &:after {
                height: 100%;
                width: 100%;
            }
        }
    }


    .modal-backdrop {
        background-color: rgb(34 31 31 / 90%);
        min-width: 100%;
        z-index: 99999;

        &.show {
            opacity: 1;
        }
    }


    .valid {
        color: ${hover};
        position: absolute;
        font-size: 12px;
        top: 44px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .swiper-button-disabled{
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
        transition: 0.7s all ease;
    }

    //menu fixed
    .scroll-down .menu-desktop {
        transform: translate3d(0, -100%, 0);
    }

    .scroll-up .menu-desktop {
        background-color: #fff;

        .menu-desktop__hamburger__lines {
            &__inner .line {
                background-color: #191818;
            }

            p {
                color: #191818;
            }
        }

        .menu-desktop__hamburger .controller svg {
            path {
                fill: #191818;
            }

            line {
                stroke: #191818;
            }
        }

        .dc-btn a {
            color: #191818;

            &:hover {
                color: #191818 !important;
            }

            &:after, &:before {
                box-shadow: 0 0 0 1px #191818;
            }
        }
    }

    .form-control:disabled {
        background-color: transparent;
    }

    @media (max-width: 600px) {
        .prevent-overflow {
            overflow: hidden;
            height: 100vh;
        }
    }

    .Toastify__toast-container {
        z-index: 999999999999999999999999 !important;
    }
    
    .toast-info{
       a{
           color: #E62E43;
       }
    }

    .mobile-menu {
        #fb-root, .fb_reset {
            display: none !important;
            opacity: 0 !important;
            visibility: hidden !important;
        }
    }

    .slick-slide {
        -webkit-transform: translate3d(0, 0, 0);
    }


    //------------------------animation
    .split-parent {
        overflow: hidden;
    }

    .split-child {
        overflow: hidden;
    }

    .reveal {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            transform-origin: left;
        }

        .global-image {
            background: transparent;
        }
    }

    /* #smooth-content {
        will-change: transform;
   
        
    } */
    /**
      * Reinstate scrolling for non-JS clients
      */
    /* .simplebar-content-wrapper {
        scrollbar-width: auto;
        -ms-overflow-style: auto;
    }

    .simplebar-content-wrapper::-webkit-scrollbar,
    .simplebar-hide-scrollbar::-webkit-scrollbar {
        display: initial;
        width: initial;
        height: initial;
    } */
    .page-transition {
        display: none;
    }

    form {
        .form-control {
            border: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            background-color: transparent !important;
            padding: 0 0 20px 0;
            margin-bottom: 40px;
            outline: none;
            color: #ffffff;
            box-shadow: none;

            &::placeholder {
                color: #999999;
            }

            &:focus {
                border-color: #FFF;
            }
        }

        textarea {
            min-height: 80px;
            max-height: 80px;
        }
    }

    .modify-footer .footer {
        @media (min-width: 600px) {
            padding-top: 200px;
        }
    }

    //global form
    .global-popup form .form-control {
        border-bottom: 1px solid rgba(10, 14, 18, 0.3);
        color: ${Black};
        padding: 0 0 15px 0;
        margin-bottom: 30px;

        &::placeholder {
            color: #999999 !important;
            text-transform: capitalize;
        }

        &:focus {
            border-bottom: 1px solid ${Black};
        }
    }

    .services {
        background-color: ${Black};

        .working, .insights {
            padding-top: 0;
        }

        .amenities {
            @media (max-width: 992px) {
                padding-top: 0;
            }
        }
    }

    .career-detail {
        background-color: ${Black};
        padding-top: 140px;
        padding-bottom: 150px;
        overflow: hidden;
    }

    .team-bio {
        background-color: ${Black};
        padding-top: 140px;
    }

    .slider-nav {
        display: flex;
        gap: 0 20px;
        margin-top: 20px;

        li {
            height: 30px;
            width: 30px;
            background-color: ${hover};
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;

            &:after {
                position: absolute;
                height: 100%;
                width: 0%;
                background-color: #191919;
                transition: .5s ${Transition};
                content: '';
                left: 0;
                bottom: 0;
            }

            img {
                position: relative;
                z-index: 2;

            }

            &:hover {
                &:after {
                    width: 100%;
                }
            }
        }
    }

    .modal-video {
        background-color: transparent;
        height: 100vh;
        z-index: 99999;

        .modal-dialog {
            height: 100vh;
            background-color: transparent;
            min-width: 100%;
            margin: 0;
        }

        .modal-body {
            height: 100vh;
        }

        .modal-content {
            background-color: transparent;
        }

        iframe {
            height: 60vh;
            width: 60vw;
            margin: auto;
            position: absolute;
            inset: 0;
        }

        .close-modal {
            position: absolute;
            top: 40px;
            right: 30px;
            height: 30px;
            cursor: pointer;
            z-index: 99;
        }

        @media (max-width: 768px) {
            .modal-content {
                //padding: 0 20px;

                iframe {
                    width: 90vw;
                    height: 60vh;
                }

                .close-modal {
                    top: 80px;
                    right: 20px;
                }
            }
        }
        @media (max-width: 550px) {
            .modal-content iframe {
                width: 90vw;
                height: 40vh;
            }
        }


    }

    .form-group {
        position: relative;

        .form-error {
            position: absolute;
            font-size: 10px;
            color: #EC1C23 !important;
            top: 100%;
        }
    }


    //team popup
    .team-modal {
        background-color: #191919;
        height: 100vh;
        z-index: 99999;
        overflow: hidden !important;
        width: 45%;
        right: 0;
        left: auto;
        padding: 0px 100px 40px 100px !important;
        .top-text{
            font-size: 200px;
            line-height: 200px;
            color: rgb(25 25 25 / 02%);
            font-weight: bold;
            position: absolute;
            top: -20px;
            z-index: 3;
            right: 0;
        }
        .bottom-text{
            font-size: 200px;
            line-height: 200px;
            color: rgb(25 25 25 / 02%);
            font-weight: bold;
            position: absolute;
            left: 40%;
            z-index: 3;
            top: 120px;
        }

        @media(max-width: 767px){
            .team-modal__content{
                img{
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
       
        &__close {
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: transparent;
            border-radius: 50%;
            position: absolute;
            right: -10px;
            top: -60px;
            transition: .3s ease;
            border: 1px solid rgba(25, 25, 25, 0.3);


            svg {
                height: 40px;

                g, line {
                    stroke: #FFFDFA;
                    height: 8px;
                    transition: stroke 0.2s ease;
                }

                path, #Rectangle_5661 {
                    //transition: stroke-dasharray 0.3s ease-in-out;
                    transition: all 1s cubic-bezier(.25, .74, .22, .99);
                }
            }

            &:hover {
                border: 1px solid rgba(25, 25, 25, 1);
                
                svg {
                    g, line {
                        stroke: #FFF;
                        transition: stroke 0.2s ease;
                    }

                    #Rectangle_5661 {
                        opacity: 1;
                        stroke-dasharray: 140;
                    }
                }
            }
        }

        .modal-dialog {
            height: 100vh;
            background-color: transparent;
            min-width: 100%;
            margin: 0;
        }

        .modal-body {
            height: 100vh;
        }

        .modal-content {
            background-color: transparent;
            margin: 100px 0;
            //overflow: scroll;
            //height: calc(100vh - 120px);
            border: none;
            /* overflow: hidden; */
            .team-modal__content {
                h4 {
                    color: #fff;
                    font-size: 20px;
                    font-weight: 700;
                    margin-top: 24px;
                    //border-top: 1px solid rgba(245, 245, 245, 0.2);
                    margin-bottom: 5px;
                }

                h5 {
                    color: rgba(255, 255, 255, 0.5);
                    font-size: 16px;
                    margin-bottom: 40px;
                }

                p {
                    color: #fff;
                    margin-bottom: 20px;
                }

                ul {
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    li {
                        &:not(:nth-last-child(1)) {
                            margin-right: 20px;
                        }

                        a {
                            //transition: opacity .5s ease;

                            &:hover {
                                // rect {
                                    //   fill: ${text}
                                // }
                                //
                                // path {
                                    //   fill: ${White}
                                // }
                                opacity: .7;
                            }
                        }

                    }
                }
            }

            .name-social {
                display: flex;
                justify-content: space-between;
                @media (max-width: 767px) {
                    flex-direction: column;
                    h5 {
                        margin-bottom: 20px;
                    }

                    .footer__social {
                        margin-bottom: 40px;
                        margin-top: 0;
                    }
                }
            }

            .footer__social {
                display: flex;

                li {
                    a {
                        height: 25px;
                        width: 25px;
                        background-color: ${hover};
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        //transition: .3s ease;
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 0;
                            background-color: white;
                            transition: all 0.5s ease-in-out;
                            z-index: 1;
                        }

                        svg {
                            position: relative;
                            z-index: 2;

                            path {
                                transition: all 0.5s ease-in-out;
                            }
                        }

                        &:hover {
                            background-color: ${hover} !important;
                            opacity: 1 !important;

                            &:before {
                                width: 100%;
                            }

                            svg {
                                path {
                                    fill: #000;
                                }
                            }
                        }

                    }

                    &:not(:nth-last-of-type(1) a) {
                        margin-right: 15px;
                    }
                }
            }
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 0 20px 10px 20px !important;
            .close-button {
                right: 20px;
                position: fixed;
            }

            .modal-content {
                //padding-top: 80px;

                .container {
                    //height: calc(100vh - 100px);
                    //overflow: scroll;

                    h3 {
                        margin-top: 30px;
                        font-size: 28px;
                        line-height: 35px;
                    }
                }
            }

            //overflow: scroll !important;

        }
    }

    .custom-as-team{
        p{
            width: calc(100% - 15px)
        }
    }

    .menu-bg-color {
        background-color: ${hover};
    }

.menu-bg-v2{
  background: #191919;
}
    
    .team-modal.team-modal-form {
        width: 100%;
        background: white;
    }

    body.menu-opened.scroll-down .Main-menu{
        transform: translate3d(0, 0, 0) !important;
        
    }
    body.scroll-down .Main-menu {
        transform: translate3d(0, -100%, 0) !important;
    }

    body.scroll-up .Main-menu {
        transform: translate3d(0, 0, 0) !important;
        background: #191919;
    }


    body.scroll-down .main-menu-mobile__bar {
        transform: translate3d(0, -100%, 0) !important;
    }

    body.scroll-up .main-menu-mobile__bar {
        transform: translate3d(0, 0, 0) !important;
        background: #191919;
    }


    .scrollBar_Gap {
        z-index: 2;
        position: absolute;
        width: 50px;
        height: 300px;
        background: coral;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        .circle {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background: red;
        }

    }

    .contact-modal{
        ul{
            margin-top: 5px !important;
        }
        form{
            position: relative;
            z-index: 9;
        }
        /* .simplebar-wrapper{
            min-height: 70vh;
        } */
        .top-text{
            font-size: 200px;
            line-height: 200px;
            color: rgb(25 25 25 / 02%);
            font-weight: bold;
            position: absolute;
            top: -20px;
            z-index: 3;
            right: 0;
        }
        .bottom-text{
            font-size: 200px;
            line-height: 200px;
            color: rgb(25 25 25 / 02%);
            font-weight: bold;
            position: absolute;
            left: 40%;
            z-index: 3;
            top: 120px;
        }
    }

    
.width-fit{
    width: fit-content;
}
.stop-scroll{
    height: 100svh !important;
    overflow: hidden;
}

.disabled{
    pointer-events: none !important;
    opacity: .5;
}
input:disabled{
    opacity: .5 !important;
}

.in-home,.for-home{
    .modal-content{
        height: 100%;
        justify-content: center;
        margin-top: 0;
    }
    .team-modal__close{
        top: 60px;
        right: 0;
    }
}



.simplebar-scrollbar:before{
    background-color: #f1f1f1;
}
@media (min-width: 769px) {
    .opacity {
      opacity: 0;
      pointer-events: none;
    }
  }
    


   //video modal
   .modal-video {
    background-color: transparent;
    height: 100vh;
    z-index: 99999;

    .modal-dialog {
      height: 100vh;
      background-color: transparent;
      min-width: 100%;
      margin: 0;
    }

    .modal-body {
      height: 100vh;
    }

    .modal-content {
      background-color: transparent;

    }

    iframe {
      height: 60vh;
      width: 60vw;
      margin: auto;
      position: absolute;
      inset: 0;
    }

    .close-modal {
      position: absolute;
      top: 40px;
      right: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 99;
    }

    @media (max-width: 768px) {
      .modal-content {
        //padding: 0 20px;

        iframe {
          width: 90vw;
          height: 60vh;
        }

        .close-modal {
          top: 80px;
          right: 20px;
        }
      }
    }
    @media (max-width: 550px) {
      .modal-content iframe {
        width: 90vw;
        height: 40vh;
      }
    }
  }

  .as-career-modal{

    .modal-content{
      justify-content: flex-start;
    }
    
    padding-top: 100px !important;
    .team-modal__close{
        z-index: 99;
        top: -60px;
    }
    .form{
        margin-top: 100px;
    }
    .modal-content .team-modal__content h4{
        font-size: 30px !important;
        line-height: 38px !important;
    }
  }
.modal{
    z-index: 2000000000999;
}

body.menu-opened{
    .widget-visible{
        opacity: 0 !important;
    }
}


`;
